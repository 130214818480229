import React from "react"

import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ post }) => {
  const { title, description, image, date } = post.frontmatter

  return (
    <div className="grid grid-cols-1 h-5/6 md:h-auto md:grid-cols-2  mb-8 ">
      <div className="flex flex-col order-last md:order-first">
        <span className="text-xs text-gray-400 pt-1">{date}</span>

        <Link
          to={post.fields.slug}
          itemProp="url"
          className="no-underline mb-1"
        >
          <h2
            itemProp="headline"
            className="text-3xl whitespace-normal mb-4 font-hs font-semibold mt-0"
          >
            {title}
          </h2>
        </Link>
        <p className="text-base font-medium">{description}</p>
      </div>
      <Img
        className="w-48 md:h-72 md:w-72 justify-self-center md:justify-self-end"
        fluid={image.childImageSharp.fluid}
        alt="preview illustration"
      />
    </div>
  )
}
