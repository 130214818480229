import React from "react"
import { Link } from "gatsby"

// const rootStyle = "transition transform hover:scale-125 ease-out duration-150 "
export default ({ post }) => {
  const { title, date } = post.frontmatter
  return (
    <div className={`flex mb-4 relative`}>
      <div className="pb-2  flex-1">
        <span className="text-xs text-gray-400">{date}</span>

        <Link className="no-underline" to={post.fields.slug} itemProp="url">
          <h2
            itemProp="headline"
            className="text-xl whitespace-normal font-hs font-semibold mt-0"
          >
            {title}
          </h2>
        </Link>
      </div>
    </div>
  )
}
